import React from 'react';
import { makeStyles } from '@material-ui/core';
import { IoIosDesktop } from 'react-icons/io';
import { AiOutlineAntDesign } from 'react-icons/ai';
import { FaPuzzlePiece, FaSearchengin, FaServer, FaSitemap, FaChalkboardTeacher } from 'react-icons/fa';
import Mailto from 'react-protected-mailto';
import Layout from '../components/layout';
import SEO from '../components/seo';

const useStyles = makeStyles(() => ({
  title: {
    margin: 'auto',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'LatoLatinWebThin',
    marginBottom: '10px',
  },
  wandIcon: {
    float: 'right',
    fontSize: '5em',
  },
  scopesWrapper: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center',
  },
  scope: {
    // width: '200px',
    padding: '25px',
    maxWidth: '100%',
    width: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontFamily: 'LatoLatinWebThin',
  },
  scopeIcon: {
    fontSize: '5rem',
  },
}));

const Scope = (props) => {
  const { title, icon: ScopeIcon } = props;
  const classes = useStyles();

  return (
    <div className={classes.scope} {...props}>
      <div className={classes.scopeIcon}>
        <ScopeIcon />
      </div>
      {title}
    </div>
  );
};

const IndexPage = () => {
  const classes = useStyles();
  const scopes = [
    {
      name: 'Website creation/redesign',
      icon: IoIosDesktop,
    },
    {
      name: 'Web app / module creation',
      icon: FaPuzzlePiece,
    },
    {
      name: 'SEO',
      icon: FaSearchengin,
    },
    {
      name: 'UX / UI design',
      icon: AiOutlineAntDesign,
    },
    {
      name: 'Server administration',
      icon: FaServer,
    },
    {
      name: 'Domain configuration',
      icon: FaSitemap,
    },
    {
      name: 'IT training',
      icon: FaChalkboardTeacher,
    },
  ];

  return (
    <Layout>
      <SEO title="Home" />
      <h2 className={classes.title}>Expertise</h2>
      <div className={classes.scopesWrapper}>
        {scopes.map((scope, itr) => (
          <Scope key={`scope_${itr}`} icon={scope.icon} title={scope.name} />
        ))}
      </div>
      <h3 css={{ textAlign: 'center' }}>
        <Mailto email="hello@uwizy.com" headers={{ subject: 'Question from the website' }} />
      </h3>
    </Layout>
  );
};

export default IndexPage;
