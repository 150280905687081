import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import SvgLogo from '../images/uwizy_logo_full.svg';

const useStyles = makeStyles((theme) => ({
  header: {},
}));

const Header = ({ siteTitle }) => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div
        css={{
          margin: '0 auto',
          maxWidth: 960,
          padding: '1.45rem 1.0875rem',
        }}
      >
        <h1 css={{ margin: 0, display: 'flex', justifyContent: 'center' }}>
          <Link
            to="/"
            css={{
              color: 'white',
              textDecoration: 'none',
              display: 'inline-flex',
              padding: '.25rem 1rem',
            }}
          >
            <img src={SvgLogo} alt="UWIZY logo" css={{ width: '200px', margin: '0', maxwidth: '80%' }} />
          </Link>
        </h1>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
