import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {},
}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <footer className={classes.footer} {...props}>
      © {new Date().getFullYear()}, Made with ❤️ and a 💻 by{' '}
      <a href="https://huguesbureau.fr" target="_blank" rel="noopener noreferrer">
        Hugues BUREAU
      </a>
    </footer>
  );
};

export default Footer;
